<template>
  <div class="mints-style">
    <titles :title="$t('text.t6')" />
    <div class="outbox">
      <div class="box1">
        <img src="@/assets/img/img16.png" class="i1" />
      </div>
      <div class="box2">{{$t('text.t497')}}</div>
      <div class="box3">
        <div class="box3list start-center">
          <div class="box3item" v-for="item in toolList" :key="item.id" @click="goOutLink(item.href)">
            <img :src="$http.imgurl + item.avatar" class="itemi1" />
            <div class="itemt1">{{ language==1?item.title:item.etitle }}</div>
          </div>
        </div>
      </div>
      <div class="box2 between-center">
        <div>{{$t('text.t498')}}</div>
        <div @click="goAhead('/applylist')">{{$t('text.t363')}}</div>
      </div>
      <div class="box4">
        <el-card class="box-card">
          <div class="row1">
            <div class="between-center">
              <img src="@/assets/img/img61.png" class="row1i1" />
              <div class="row1t1">{{$t('text.t499')}}</div>
              <div class="row1btn center-center" @click="goAhead('/casting?type=0')">{{$t('text.t6')}}</div>
            </div>
            <div class="line2 end-center">
              <div :class="chainId==item.chain_id?'line2item-ac':'line2item'" v-for="item in chain_list" :key="item.chain_type">{{ item.name }}</div>
            </div>
          </div>
          <div class="row2">
            <div class="msb1">
              {{$t('text.t500')}}
            </div>
            <div class="msb2 between-center">
              <div class="start-center">
                <!-- <div class="msb2t1">手续费：</div>
                <img src="@/assets/img/img43.png" class="i1" />
                <div class="msb2t2">0.012</div> -->
              </div>
              <div class="start-center">
                <div class="msb2t1">{{$t('text.t396')}}：</div>
                <img src="@/assets/img/img43.png" class="i1" />
                <div class="msb2t2">True Shop</div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="row1 between-center">
            <img src="@/assets/img/img61.png" class="row1i1" />
            <div class="row1t1">{{user.type==1?$t('text.t522'):$t('text.t444')}}</div>
            <div v-if="user.type==1" class="row1btn center-center" @click="goAhead('/casting?type=1')">{{$t('text.t6')}}</div>
            <div v-else class="row1btn center-center" @click="goAhead('/apply?type=1')">{{$t('text.t474')}}</div>
          </div>
          <div class="row2">
            <div class="msb1">
              {{$t('text.t501')}}
            </div>
            <div class="msb2 between-center">
              <div class="start-center">
                <!-- <div class="msb2t1">手续费：</div>
                <img src="@/assets/img/img43.png" class="i1" />
                <div class="msb2t2">0.012</div> -->
              </div>
              <div class="start-center">
                <div class="msb2t1">{{$t('text.t396')}}：</div>
                <img src="@/assets/img/img43.png" class="i1" />
                <div class="msb2t2">True Shop</div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div class="row1 between-center">
            <img src="@/assets/img/img61.png" class="row1i1" />
            <div class="row1t1">{{user.type==2?$t('text.t523'):$t('text.t445')}}</div>
            <div v-if="user.type==2" class="row1btn center-center" @click="goAhead('/casting?type=2')">{{$t('text.t6')}}</div>
            <div v-else class="row1btn center-center" @click="goAhead('/apply?type=2')">{{$t('text.t474')}}</div>
          </div>
          <div class="row2">
            <div class="msb1">
              {{$t('text.t502')}}
            </div>
            <div class="msb2 between-center">
              <div class="start-center">
                <!-- <div class="msb2t1">手续费：</div>
                <img src="@/assets/img/img43.png" class="i1" />
                <div class="msb2t2">0.012</div> -->
              </div>
              <div class="start-center">
                <div class="msb2t1">{{$t('text.t396')}}：</div>
                <img src="@/assets/img/img43.png" class="i1" />
                <div class="msb2t2">True Shop</div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapActions } from "vuex";
import titles from "@/components/titles/index.vue";
export default {
  data(){
    return{
      toolList:[]
    }
  },
  components:{
    titles
  },
  computed: {
    ...mapState(["chainId","chain_list","user","language"]),
  },
  created(){
    this.getUser()
    this.getTool()
  },
  methods:{
    ...mapActions(["getUser"]),
    getTool(){
      this.$http.get('/core/tool',{
        page:1,
        limit:100,
      }).then(res=>{
        this.toolList = res.data.data
      })
    },
    goOutLink(url){
      window.location.href = url
    },
  }
}
</script>

<style lang="less">
.mints-style{
  .el-card__body, .el-main{
    padding: 0;
  }
  .box-card{
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .outbox{
    padding: 12px 20px 0;
    .box1{
      width: 100%;
      height: 136px;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 25px;
      .i1{
        width: 100%;
        height: 100%;
      }
    }
    .box2{
      font-size: 15px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 20px;
    }
    .box3{
      width: 100%;
      overflow-x: auto;
      margin-bottom: 25px;
      .box3list{
        .box3item{
          flex-shrink: 0;
          margin-right: 25px;
          text-align: center;
          .itemi1{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-bottom: 8px;
          }
          .itemt1{
            font-size: 12px;
            font-weight: 500;
            color: #323232;
          }
        }
      }
    }
    .box4{
      .row1{
        padding: 10px 16px 15px 10px;
        border-bottom: 1px solid #E1E1E1;
        .row1i1{
          width: 45px;
          height: 45px;
          border-radius: 6px;
          margin-right: 15px;
        }
        .row1t1{
          flex: 1;
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .row1btn{
          background: #323232;
          border-radius: 25px;
          padding: 7px 14px;
          font-size: 12px;
          font-weight: bold;
          color: #FFFFFF;
          box-shadow: 2px -3px 0 #ccc;
        }
        .line2{
          .line2item{
            background: #FFFFFF;
            border: 1px solid #909090;
            border-radius: 15px;
            padding: 5px 10px;
            margin-right: 10px;
            color: #909090;
          }
          .line2item-ac{
            background: #FFFFFF;
            border: 1px solid #323232;
            border-radius: 15px;
            padding: 5px 10px;
            margin-right: 10px;
            color: #323232;
          }
        }
      }
      .row2{
        padding: 15px 12px 10px;
        .msb2{
          .msb2t1{
            font-size: 12px;
            font-weight: 500;
            color: #909090;
          }
          .i1{
            width: 12px;
            height: 12px;
            margin: 0 5px;
          }
          .msb2t2{
            font-size: 13px;
            font-weight: 500;
            color: #323232;
          }
        }
        .msb1{
          font-size: 12px;
          font-weight: 500;
          color: #909090;
          margin-bottom: 22px;
        }
      }
    }
  }
}
</style>